exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-pois-tsx": () => import("./../../../src/pages/pois.tsx" /* webpackChunkName: "component---src-pages-pois-tsx" */),
  "component---src-pages-select-tsx": () => import("./../../../src/pages/select.tsx" /* webpackChunkName: "component---src-pages-select-tsx" */),
  "component---src-pages-yritys-[id]-index-tsx": () => import("./../../../src/pages/yritys/[id]/index.tsx" /* webpackChunkName: "component---src-pages-yritys-[id]-index-tsx" */),
  "component---src-pages-yritys-[id]-oma-tsx": () => import("./../../../src/pages/yritys/[id]/oma.tsx" /* webpackChunkName: "component---src-pages-yritys-[id]-oma-tsx" */),
  "component---src-pages-yritys-[id]-vuokra-tsx": () => import("./../../../src/pages/yritys/[id]/vuokra.tsx" /* webpackChunkName: "component---src-pages-yritys-[id]-vuokra-tsx" */),
  "component---src-pages-yritys-index-tsx": () => import("./../../../src/pages/yritys/index.tsx" /* webpackChunkName: "component---src-pages-yritys-index-tsx" */)
}

